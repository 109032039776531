import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import fetchData from "../../services/requester-website";
import parse from "html-react-parser";
import WhatsAppIcon from "../../assets/images/website/whatsapp-icon.svg";
import EmailIcon from "../../assets/images/website/email-icon.svg";
import HelpIcon from "../../assets/images/website/help-icon.svg";
import WebLoader from "../../components/website/web-loader";
import { Form, Input, Button } from "antd";
import { imagePath } from "../../helpers/global";

const DealDetails = (props) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const { phone, email } = props;

  const getData = () => {
    const { id } = params;

    let reqURL = "deal/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const onFinish = (values) => {
    // let reqURL = "customers/add";
    // let reqOBJ = values;
    // reqOBJ.birthDate = "2000-01-01T00:00:00.000Z";
    // reqOBJ.expiryDate = "2000-01-01T00:00:00.000Z";
    // fetchData(reqURL, reqOBJ, (data) => {
    //   let customer = values;
    //   customer.customerId = data;
    //   data && props.handleAdd(customer);
    // });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-details deal-details">
      {!loading && (
        <div className="container">
          <div className="pt-4">
            <div className="row">
              <div className="col-lg-8 web-details-left-col">
                {data?.imageURL && (
                  <div className="web-details-image">
                    <img src={imagePath() + data?.imageURL} alt={data?.title} />
                  </div>
                )}

                {data?.overview && (
                  <div className="web-details-sec">
                    <h3>Overview</h3>
                    <div>{parse("" + data?.overview + "")}</div>
                  </div>
                )}

                {data?.itinerary && (
                  <div className="web-details-sec">
                    <h3>Itinerary</h3>
                    <div>{parse("" + data?.itinerary + "")}</div>
                  </div>
                )}

                {data?.inclusions && (
                  <div className="web-details-sec">
                    <h3>Inclusions</h3>
                    <div>{parse("" + data?.inclusions + "")}</div>
                  </div>
                )}

                {data?.exclusions && (
                  <div className="web-details-sec">
                    <h3>Exclusions</h3>
                    <div>{parse("" + data?.exclusions + "")}</div>
                  </div>
                )}

                {data?.terms && (
                  <div className="web-details-sec">
                    <h3>Terms</h3>
                    <div>{parse("" + data?.terms + "")}</div>
                  </div>
                )}
              </div>

              <div className="col-lg-4 web-details-right-col">
                <div className="web-details-info">
                  <div className="web-details-title">
                    <h2>{data?.title}</h2>
                    <p className="text-secondary">{data?.location}</p>
                  </div>
                  <div className="web-details-info-wrap">
                    <div className="web-details-price">
                      <span className="text-secondary small">Starts from</span>
                      <h3>{data?.price}</h3>
                      <span className="text-secondary small">
                        per person on twin sharing
                      </span>
                    </div>

                    <div className="web-details-support">
                      <h3>Need Help?</h3>
                      <div className="d-flex align-items-center ms-2">
                        <img src={HelpIcon} alt="" />
                        <div className="ms-4">
                          <div>
                            Call us : <b>{phone}</b>
                          </div>
                          <div className="mt-1">
                            Mail us :{" "}
                            <Link to={"mailto:" + email}>{email}</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row web-details-btns g-0">
                      <div className="col-lg-6 pe-2">
                        <Link
                          className="btn btn-whatsapp"
                          target="_blank"
                          to={
                            "https://web.whatsapp.com/send?phone=" +
                            phone +
                            "&text=" +
                            encodeURIComponent(
                              "I am interested: " + window.location
                            )
                          }
                        >
                          <img src={WhatsAppIcon} alt="" />
                          WhatsApp
                        </Link>
                        <Link
                          className="btn btn-whatsapp btn-whatsapp-mobile d-none"
                          target="_blank"
                          to={
                            "https://wa.me/" +
                            phone +
                            "?text=" +
                            encodeURIComponent(
                              "I am interested: " + window.location
                            )
                          }
                        >
                          <img src={WhatsAppIcon} alt="" />
                          WhatsApp
                        </Link>
                      </div>
                      <div className="col-lg-6 ps-2">
                        <Link className="btn btn-email" to={"mailto:" + email}>
                          <img src={EmailIcon} alt="" />
                          Email Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="web-details-inquiry mt-4">
                  <h3>Send Inquiry</h3>
                  <Form
                    name="itinerary-add"
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input First and Last Name!",
                        },
                      ]}
                    >
                      <Input placeholder="First and Last Name" />
                    </Form.Item>

                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please input Email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email Address" />
                    </Form.Item>

                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input Phone Number!",
                        },
                      ]}
                    >
                      <Input placeholder="Phone Number" />
                    </Form.Item>

                    <Form.Item label="Comments" name="comments">
                      <Input.TextArea placeholder="Comments" />
                    </Form.Item>

                    <Form.Item
                      wrapperCol={{
                        offset: 6,
                        span: 16,
                      }}
                    >
                      <Button className="btn btn-inquiry-add" htmlType="submit">
                        Submit Inquiry
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading && <WebLoader />}
    </div>
  );
};

export default DealDetails;
