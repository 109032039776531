import React from "react";

const WebLoader = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-wrap"
      style={{ minHeight: "calc(100vh - 158px)" }}
    >
      <div className="text-center">
        <div className="spinner-border text-secondary"></div>
        <div className="small text-secondary mt-2">loading</div>
      </div>
    </div>
  );
};

export default WebLoader;
