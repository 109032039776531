import React from "react";
import { Form, Input, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const CustomerQuickAdd = (props) => {
  const onFinish = (values) => {
    let reqURL = "customers/add";
    let reqOBJ = values;
    reqOBJ.birthDate = "2000-01-01T00:00:00.000Z";
    reqOBJ.expiryDate = "2000-01-01T00:00:00.000Z";
    reqOBJ.customerType = props?.customerType;

    fetchData(reqURL, reqOBJ, (data) => {
      if (data && data !== "Record Already Exists") {
        let customer = values;
        customer.customerId = data;
        props.handleAdd(customer);
      } else {
        message.error("Customer Already Exists");
      }
    });
  };

  return (
    <Form
      name="itinerary-add"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message:
              props?.customerType === "Individual"
                ? "Please input First and Last Name!"
                : "Please input Corporate Name",
          },
        ]}
      >
        <Input
          placeholder={
            props?.customerType === "Individual"
              ? "First and Last Name"
              : "Corporate / Company Name"
          }
        />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input Email Id!",
          },
        ]}
      >
        <Input placeholder="Email Id" />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="phone"
        rules={[
          {
            required: true,
            message: "Please input Phone Number!",
          },
        ]}
      >
        <Input placeholder="Phone Number" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button icon={<PlusOutlined />} type="primary" htmlType="submit">
          Add Customer
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CustomerQuickAdd;
