import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Typography } from "antd";
import fetchData from "../services/requester";

const CustomerView = (props) => {
  const [data, setData] = useState("");
  const { Text } = Typography;

  const getData = () => {
    const { customerId } = props;
    let reqURL = "customers/" + customerId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
        }
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ marginTop: "24px" }}
      >
        <Text style={{ fontWeight: "600" }}>Personal Details</Text>
      </Divider>
      <Descriptions column={2}>
        <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Type">
          <Text type="success">{data?.customerType}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
        <Descriptions.Item label="Phone">{data?.phone}</Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0">
        <Text style={{ fontWeight: "600" }}>Address Details</Text>
      </Divider>
      <Descriptions column={2}>
        <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>
        <Descriptions.Item label="City">{data?.city}</Descriptions.Item>
        <Descriptions.Item label="Country">{data?.country}</Descriptions.Item>
        <Descriptions.Item label="Postal Code">
          {data?.postalCode}
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" orientationMargin="0">
        <Text style={{ fontWeight: "600" }}>Other Details</Text>
      </Divider>
      <Descriptions column={2}>
        <Descriptions.Item label="PAN Number">
          {data?.panNumber}
        </Descriptions.Item>
        <Descriptions.Item label="GST Number">
          {data?.gstNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Document Type">
          {data?.documentType}
        </Descriptions.Item>
        <Descriptions.Item label="Number">
          {data?.documentNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Nationality">
          {data?.nationality}
        </Descriptions.Item>
        <Descriptions.Item label="Issuing Country">
          {data?.issuingCountry}
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  );
};

export default CustomerView;
