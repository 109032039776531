import React from "react";

const WebsiteFooter = (props) => {
  return (
    <footer className="web-footer">
      <div className="container">
        <div className="text-center text-secondary small">
          Copyright 2024-2025 by {props?.company}
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
