import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import fetchData from "../../services/requester";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const PageAdd = (props) => {
  const [data, setData] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const getData = () => {
    const { pageId } = props;
    let reqURL = "pages/" + pageId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setDefaultData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      pageTitle: data?.pageTitle,
      pageName: data?.pageName,
      pageContent: data?.pageContent,
    });
  };

  const onFinish = (values) => {
    let reqURL = "pages/" + (props.isEditMode ? "update" : "add");
    let reqOBJ = values;

    props?.isEditMode && (reqOBJ.pageId = props?.pageId);

    fetchData(reqURL, reqOBJ, (data) => {
      data && props.handleAdd();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    props?.isEditMode && getData();
  }, []);

  return (
    <React.Fragment>
      <Form
        name="inquiry-add"
        form={form}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 18,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{}}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Page Title"
              name="pageTitle"
              rules={[
                {
                  required: true,
                  message: "Please input Page Title!",
                },
              ]}
            >
              <Input placeholder="Enter Page Title" />
            </Form.Item>
            <Form.Item
              label="Page Name"
              name="pageName"
              rules={[
                {
                  required: true,
                  message: "Please input Page Name!",
                },
              ]}
            >
              <Input placeholder="Enter Page Name" />
            </Form.Item>
            <Form.Item
              label="Page Content"
              name="pageContent"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
            >
              <CKEditor
                data={data?.pageContent || ""}
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "480px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 5,
            span: 15,
          }}
          className="btn-row"
        >
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {!props?.isEditMode ? "Add Page" : "Update Page"}
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default PageAdd;
