import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select, Row, Col, Radio } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";
import { genderList, countryList, documentTypeList } from "../helpers/lookups";

const CustomerAdd = (props) => {
  const [data, setData] = useState("");
  const [customerType, setCustomerType] = useState("Individual");
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const getData = () => {
    const { customerId } = props;
    let reqURL = "customers/" + customerId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setDefaultData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      customerType: data?.customerType,
      gender: data?.gender,
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      country: data?.country,
      city: data?.city,
      address: data?.address,
      postalCode: data?.postalCode,
      documentType: data?.documentType,
      documentNumber: data?.documentNumber,
      nationality: data?.nationality,
      issuingCountry: data?.issuingCountry,
      panNumber: data?.panNumber,
      gstNumber: data?.gstNumber,
    });
  };

  const handleValuesChange = (value, values) => {
    if (value["customerType"]) {
      setCustomerType(value["customerType"]);
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "customers/" + (props?.isEditMode ? "update" : "add");
    let reqOBJ = values;

    props?.isEditMode && (reqOBJ.customerId = props?.customerId);
    reqOBJ.birthDate = "2000-01-01T00:00:00.000Z";
    reqOBJ.expiryDate = "2000-01-01T00:00:00.000Z";

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      if (data && data !== "Record Already Exists") {
        let customer = values;
        customer.customerId = data;
        props.handleAdd(customer);
      } else {
        message.error("Customer Already Exists");
      }
    });
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  useEffect(() => {
    props?.isEditMode && getData();
  }, []);

  return (
    <Form
      name="customer-add"
      form={form}
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      autoComplete="off"
      {...layout}
      initialValues={{
        customerType,
        country: "India",
        issuingCountry: "India",
        nationality: "India",
      }}
    >
      <Row>
        <Col span={12}>
          <Form.Item label="Type" name="customerType">
            <Radio.Group>
              <Radio.Button value="Individual">Individual</Radio.Button>
              <Radio.Button value="Corporate">Corporate</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message:
                  customerType === "Individual"
                    ? "Please input First and Last Name!"
                    : "Please input Corporate Name",
              },
            ]}
          >
            <Input
              placeholder={
                customerType === "Individual"
                  ? "First and Last Name"
                  : "Corporate / Company Name"
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Gender" name="gender">
            <Select placeholder="Select Gender">
              {genderList?.map((x, key) => (
                <Select.Option value={x?.value} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input Email Id!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input Phone Number!",
              },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Country" name="country">
            <Select placeholder="Select Country" showSearch>
              {countryList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="City" name="city">
            <Input placeholder="City" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Address" name="address">
            <Input placeholder="Address" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Postal Code" name="postalCode">
            <Input placeholder="Postal Code" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Document" name="documentType">
            <Select placeholder="Select Document Type">
              {documentTypeList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Number" name="documentNumber">
            <Input placeholder="Document Number" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="Issued by" name="issuingCountry">
            <Select placeholder="Select Issuing Country" showSearch>
              {countryList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Nationality" name="nationality">
            <Select placeholder="Select Nationality" showSearch>
              {countryList?.map((x, key) => (
                <Select.Option value={x?.name} key={key}>
                  {x?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item label="PAN Number" name="panNumber">
            <Input placeholder="PAN Number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="GST Number" name="gstNumber">
            <Input placeholder="GST Number" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 16,
        }}
      >
        <Button
          icon={!props?.isEditMode ? <PlusOutlined /> : <EditOutlined />}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {(!props?.isEditMode ? "Add " : "Update") + " Customer"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CustomerAdd;
