import React, { useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import fetchData from "../../services/requester";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadImg from "../upload-img";

const WhyusAdd = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const onFinish = (values) => {
    let reqURL = "whyus/" + (props.isEditMode ? "update" : "add");
    let reqOBJ = values;

    props?.isEditMode && (reqOBJ.whyusId = props?.whyusId);

    fetchData(reqURL, reqOBJ, (data) => {
      data && props.handleAdd();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleUpload = (imageURL) => {
    form.setFieldsValue({ imageURL });
  };

  if (props?.isEditMode) {
    form.setFieldsValue({
      title: props?.title,
      subTitle: props?.subTitle,
      imageURL: props?.imageURL,
      description: props?.description,
    });
  }

  return (
    <React.Fragment>
      <Form
        name="whyus-add"
        form={form}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 18,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{}}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input Page Title!",
                },
              ]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
            <Form.Item label="Sub Title" name="subTitle">
              <Input placeholder="Enter Sub Title" />
            </Form.Item>
            <Form.Item label="Image" name="imageURL">
              <UploadImg
                path="uploadfile"
                type="file-input"
                handleUpload={handleUpload}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
            >
              <CKEditor
                data={props?.description || ""}
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "280px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 5,
            span: 15,
          }}
          className="btn-row"
        >
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {!props?.isEditMode ? "Add Whyus" : "Update Whyus"}
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default WhyusAdd;
