import moment from "moment";

export const Date = (date) => {
  let result = moment(date).format("DD/MM/YYYY");
  return result;
};

export const Time = (time) => {
  let result = moment(time).format("HH:MM");
  return result;
};

export const DateTime = (date) => {
  let result = moment(date).format("MMM DD, YYYY HH:MM A");
  return result;
};

export const Currency = (currency) => {
  let x = Number(currency);
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  let res =
    x.length > 3
      ? !x.includes("-")
        ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
        : x.length > 4
        ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
        : x
      : x;
  return "₹ " + res;
};
