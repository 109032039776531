import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetchData from "../../services/requester-website";
import parse from "html-react-parser";
import WebLoader from "../../components/website/web-loader";

const WebPage = ({ menu }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const getData = () => {
    const { page } = params;

    let id = menu?.find((x) => x?.pageName === page)?.pageId;
    let reqURL = "pages/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  useEffect(() => {
    menu.length > 0 && getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-page">
      <div className="web-page-title">
        <div className="container">
          <h1>{data?.pageTitle}</h1>
        </div>
      </div>

      {!loading && (
        <div className="container">
          <div className="pt-3">
            <div>{parse("" + data?.pageContent + "")}</div>
          </div>
        </div>
      )}

      {loading && <WebLoader />}
    </div>
  );
};

export default WebPage;
