import config from "../config.json";

export const imagePath = () => {
  const portalId = localStorage?.getItem("portalId");

  const imagePath =
    config.mode === "dev"
      ? config?.devPath?.replace("/api/", "/images/") + portalId + "/"
      : config?.livePath?.replace("/api/", "/images/") + portalId + "/";

  return imagePath;
};
