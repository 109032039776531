import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppPublic from "./App-Public";
import AppAdmin from "./App-Admin";
import AppWebsite from "./App-Website";

function App() {
  const [type, setType] = useState("");
  const location = useLocation();

  const setPageType = (path) => {
    let type = "";

    if (path.split("/")[1] === "admin") {
      type = localStorage.getItem("admin-token") ? "admin" : "not-found";
    } else {
      type = "website";
    }
    setType(type);
  };

  useEffect(() => {
    setPageType(location.pathname);
  }, [location]);

  return (
    <React.Fragment>
      {type === "admin" && <AppAdmin />}

      {type === "website" && <AppWebsite />}

      {type === "not-found" && (
        <h2 style={{ textAlign: "center", opacity: "0.2" }}>Page not Found</h2>
      )}
    </React.Fragment>
  );
}

export default App;
