import React, { useEffect, useState } from "react";
import {
  List,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Drawer,
  Tooltip,
  Avatar,
  Modal,
  message,
  Table,
} from "antd";
import {
  CalculatorOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  MenuOutlined,
  FilterOutlined,
  TableOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileImageOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Date } from "../helpers/formatter";
import fetchData from "../services/requester";
import Summary from "../components/summary";
import LeadAdd from "../components/lead-add";
import LeadView from "../components/lead-view";
import AddQuickProposal from "../components/add-quick-proposal";
import CommonFilters from "../components/common-filters";

const Leads = (props) => {
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLogModalVisible, setIsLogModalVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isFiltersVisible, setFiltersVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [visibleProposal, setVisibleProposal] = useState(false);
  const [view, setView] = useState("grid");
  const { Title, Text } = Typography;

  const getData = () => {
    let reqURL = "leads";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setDefaultData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const menuItems = [
    {
      label: "View Lead",
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Edit Lead",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete Lead",
      key: "delete",
      icon: <DeleteOutlined />,
    },
    // {
    //   label: "Add Proposal",
    //   key: "add-proposal",
    // },
    // {
    //   label: "Activity Log",
    //   key: "log",
    // },
  ];

  const handleMenuClick = (key, item) => {
    setDetails(item);
    switch (key) {
      case "view":
        setIsModalVisible(true);
        break;

      case "edit":
        setIsEditMode(true);
        showDrawer();
        break;

      case "delete":
        setConfirmVisible(true);
        break;

      case "log":
        setIsLogModalVisible(true);
        break;

      default:
        break;
    }
  };

  const handleClick = (item) => {
    setIsModalVisible(true);
    setDetails(item);
  };

  const onClose = () => {
    setIsEditMode(false);
    setVisible(false);
  };

  const onFiltersClose = () => {
    setFiltersVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const showFiltersDrawer = () => {
    setFiltersVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLogCancel = () => {
    setIsLogModalVisible(false);
  };

  const handleAdd = (response) => {
    message.success(
      !isEditMode ? "Lead added successfully" : "Lead updated successfully"
    );
    setVisible(false);
    setIsEditMode(false);
    getData();
  };

  const handleDelete = () => {
    setConfirmLoading(true);

    const { leadId } = details;
    let reqURL = "leads/" + leadId;
    let reqOBJ = "";

    fetchData(reqURL, reqOBJ, (data) => {
      setConfirmVisible(false);
      setConfirmLoading(false);
      data && message.success("Lead deleted successfully");
      setLoading(true);
      data && getData();
    });
  };

  const changeView = () => {
    setView(view === "grid" ? "list" : "grid");
  };

  const handleFilters = (filters) => {
    setFiltersVisible(false);

    const filteredData = defaultData?.filter(
      ({ title, bookingFor, name, email, phone }) =>
        (!filters?.title ||
          title?.toLowerCase()?.includes(filters?.title?.toLowerCase())) &&
        (!filters?.bookingFor ||
          bookingFor
            ?.toLowerCase()
            ?.includes(filters?.bookingFor?.toLowerCase())) &&
        (!filters?.name ||
          name?.toLowerCase()?.includes(filters?.name?.toLowerCase())) &&
        (!filters?.email ||
          email?.toLowerCase()?.includes(filters?.email?.toLowerCase())) &&
        (!filters?.phone || phone?.includes(filters?.phone))
    );

    setData(filteredData);
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Lead Type",
      dataIndex: "leadType",
      key: "leadType",
    },
    {
      title: "Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => Date(text),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (text) => "Rs " + text,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => Date(text),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <Tooltip placement="bottom" title={"Quick Proposal"}>
            <Button
              icon={<FileAddOutlined />}
              onClick={() => showProposalDrawer(record)}
              style={{
                border: "0px",
                background: "none",
                boxShadow: "none",
              }}
            />
          </Tooltip>
          <Dropdown
            menu={{
              items: menuItems,
              onClick: (e) => handleMenuClick(e.key, record),
            }}
            icon
          >
            <Button
              style={{
                border: "0px",
                background: "none",
                boxShadow: "none",
              }}
            >
              <MenuOutlined />
            </Button>
          </Dropdown>
        </div>
      ),
      width: 50,
    },
  ];

  const showProposalDrawer = (item) => {
    setDetails(item);
    setVisibleProposal(true);
  };

  const onProposalClose = () => {
    setIsEditMode(false);
    setVisibleProposal(false);
  };

  const handleProposalAdd = (response) => {
    message.success(
      !isEditMode
        ? "Quick Proposal added successfully"
        : "Quick Proposal updated successfully"
    );
    setVisibleProposal(false);
    setIsEditMode(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <MailOutlined style={{ marginRight: "4px" }} /> Leads
          </React.Fragment>
        }
        extra={[
          <Tooltip title="Change View" placement="bottom" key={1}>
            <Button
              key={1}
              type="default"
              icon={
                view === "grid" ? <TableOutlined /> : <UnorderedListOutlined />
              }
              onClick={changeView}
            >
              Views
            </Button>
          </Tooltip>,
          <Tooltip title="Filters" placement="bottom" key={2}>
            <Button
              key={2}
              type="default"
              icon={<FilterOutlined />}
              onClick={showFiltersDrawer}
            >
              Filters
            </Button>
          </Tooltip>,
          <Button
            key={3}
            type="primary"
            icon={<PlusOutlined />}
            onClick={showDrawer}
          >
            Add Lead
          </Button>,
        ]}
        ghost={false}
        style={{ border: "0px none" }}
      />

      <Summary data={data} key={loading + 1} loading={loading} />

      {view === "grid" && (
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={data}
          className="general-list"
          renderItem={(item) => (
            <List.Item
              actions={[
                <Tooltip placement="bottom" title={"Quick Proposal"}>
                  <Button
                    icon={<FileAddOutlined />}
                    onClick={() => showProposalDrawer(item)}
                    style={{
                      border: "0px",
                      background: "none",
                      boxShadow: "none",
                    }}
                  />
                </Tooltip>,
                <Dropdown
                  menu={{
                    items: menuItems,
                    onClick: (e) => handleMenuClick(e.key, item),
                  }}
                  icon
                >
                  <Button
                    style={{
                      border: "0px",
                      background: "none",
                      boxShadow: "none",
                    }}
                  >
                    <MenuOutlined />
                  </Button>
                </Dropdown>,
              ]}
            >
              <Row
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() => handleClick(item)}
              >
                <Col flex="auto" className="lead-col-1">
                  <Row gutter={16}>
                    <Col
                      flex="none"
                      style={{ display: "flex", alignItems: "center" }}
                      className="lead-avatar"
                    >
                      <Avatar size={52} shape="square">
                        <span
                          style={{
                            fontSize: "18px",
                            textTransform: "uppercase",
                            lineHeight: "18px",
                          }}
                        >
                          {item?.name?.charAt(0)}
                        </span>
                      </Avatar>
                    </Col>
                    <Col flex="auto">
                      <Title
                        level={5}
                        style={{
                          textTransform: "capitalize",
                          margin: "0px 0px 6px 0px",
                        }}
                      >
                        {item?.name}
                      </Title>

                      <Text type="secondary">
                        <MailOutlined /> {item?.email}
                      </Text>
                      <Text type="secondary" style={{ marginLeft: "16px" }}>
                        <PhoneOutlined /> {item?.phone}
                      </Text>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} xl={9} className="lead-col-2">
                  <Text
                    strong
                    style={{
                      display: "block",
                      marginBottom: "6px",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.title}
                  </Text>
                  <Text type="secondary">
                    <CalendarOutlined /> {Date(item?.startDate)}
                  </Text>
                  <Text type="secondary" style={{ marginLeft: "16px" }}>
                    <ClockCircleOutlined /> {item?.duration} days
                  </Text>
                  <Text type="secondary" style={{ marginLeft: "16px" }}>
                    <CalculatorOutlined /> Rs {item?.budget}
                  </Text>
                  <Text type="secondary" style={{ marginLeft: "16px" }}>
                    <FileImageOutlined /> {item?.leadType}
                  </Text>
                </Col>

                <Col xs={24} xl={3} className="lead-col-3">
                  <Text
                    style={{ display: "block", textTransform: "capitalize" }}
                    type="warning"
                  >
                    {item?.status}
                  </Text>
                  <Text
                    style={{ textTransform: "capitalize" }}
                    type="secondary"
                  >
                    Created: {Date(item?.createdDate)}
                  </Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}

      {view === "list" && (
        <Table
          columns={columns}
          rowKey={(record) => record?.leadId}
          dataSource={data || []}
          loading={loading}
          size="small"
        />
      )}

      <Drawer
        title={!isEditMode ? "Add Lead" : "Update Lead"}
        placement="right"
        onClose={onClose}
        open={visible}
        size={"large"}
      >
        <LeadAdd
          isEditMode={isEditMode}
          {...details}
          key={visible}
          handleAdd={handleAdd}
        />
      </Drawer>

      <Drawer
        title="Filters"
        placement="right"
        onClose={onFiltersClose}
        open={isFiltersVisible}
      >
        <CommonFilters handleFilters={handleFilters} screen={"leads"} />
      </Drawer>

      <Drawer
        title={!isEditMode ? "Add Quick Proposal" : "Update Quick Proposal"}
        placement="right"
        onClose={onProposalClose}
        open={visibleProposal}
        size={"large"}
      >
        <AddQuickProposal
          isEditMode={isEditMode}
          {...details}
          portal={props}
          key={visibleProposal}
          handleAdd={handleProposalAdd}
        />
      </Drawer>

      <Modal
        title={
          <span style={{ textTransform: "capitalize" }}>
            {details?.name + " " + " - " + details?.title}
          </span>
        }
        footer={null}
        centered
        open={isModalVisible}
        onCancel={handleCancel}
        key={isModalVisible}
        width={720}
      >
        <LeadView {...details} key={isModalVisible} />
      </Modal>

      <Modal
        title="Activity Log"
        open={isLogModalVisible}
        footer={null}
        onCancel={handleLogCancel}
        centered
        width={420}
        key={details?.id}
      >
        {/* <LeadLog {...details} key={isLogModalVisible} /> */}
      </Modal>

      <Modal
        title={"Confirm Delete"}
        open={isConfirmVisible}
        onCancel={() => setConfirmVisible(false)}
        centered
        width={320}
        footer={[
          <div style={{ textAlign: "center" }} key={"test"}>
            <Button
              type="primary"
              loading={isConfirmLoading}
              danger
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div style={{ margin: "16px 0px" }}>
          Are you sure to delete this Item?
        </div>
      </Modal>
    </div>
  );
};

export default Leads;
