import React from "react";
import { Form, Input, Button, Radio } from "antd";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";

const CommonFilters = (props) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    props.handleFilters(values);
  };

  const handleClear = () => {
    form.setFieldsValue({
      bookingFor: "",
      customerType: "",
      title: "",
      name: "",
      email: "",
      phone: "",
    });
    props.handleFilters({});
  };

  return (
    <Form
      name="cutomer-filters"
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Type"
        name={props?.screen === "leads" ? "bookingFor" : "customerType"}
      >
        <Radio.Group>
          <Radio.Button value="Individual">Individual</Radio.Button>
          <Radio.Button value="Corporate">Corporate</Radio.Button>
        </Radio.Group>
      </Form.Item>

      {props?.screen === "leads" && (
        <React.Fragment>
          <Form.Item label="Title" name="title">
            <Input placeholder="Title" />
          </Form.Item>
          {/* <Form.Item label="Lead Type" name="type">
            <Input placeholder="Lead Type" />
          </Form.Item>
          <Form.Item label="Trip Type" name="tripType">
            <Input placeholder="Trip Type" />
          </Form.Item>
          <Form.Item label="Lead Source" name="source">
            <Input placeholder="Lead Source" />
          </Form.Item>
          <Form.Item label="Assign To" name="assignedTo">
            <Input placeholder="Assign To" />
          </Form.Item> */}
        </React.Fragment>
      )}

      <Form.Item label="Name" name="name">
        <Input placeholder="First and Last Name" />
      </Form.Item>

      <Form.Item label="Email" name="email">
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item label="Phone" name="phone">
        <Input placeholder="Phone" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
        className="btn-row"
      >
        <Button icon={<FilterOutlined />} type="primary" htmlType="submit">
          Apply
        </Button>
        <Button
          icon={<CloseCircleOutlined />}
          type="default"
          onClick={handleClear}
        >
          Clear
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CommonFilters;
