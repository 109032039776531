import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetchData from "../../services/requester-website";
import WebLoader from "../../components/website/web-loader";
import parse from "html-react-parser";
import { Form, Input, Button } from "antd";
import { imagePath } from "../../helpers/global";

const QuickProposalDetails = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const getData = () => {
    const { id } = params;
    let reqURL = "quick-proposals/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const onFinish = () => {};

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-details quick-proposal-details">
      {!loading && (
        <div className="container">
          <div className="pt-4">
            <div className="row">
              <div className="col-lg-8">
                {data?.imageURL && (
                  <div className="web-details-image">
                    <img src={imagePath() + data?.imageURL} alt={data?.title} />
                  </div>
                )}

                <div className="web-details-sec">
                  <h3>{data?.title}</h3>
                  <div>{parse("" + data?.details + "")}</div>
                  <h4>Price: {data?.offerPrice}</h4>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="web-details-info">
                  <div className="web-details-title">
                    <h2>Quick Reply</h2>
                  </div>{" "}
                  <div className="web-details-info-wrap">
                    <Form
                      name="quickproposal-add"
                      onFinish={onFinish}
                      autoComplete="off"
                    >
                      <Form.Item label="" name="title">
                        <Input.TextArea placeholder="Type your message here..." />
                      </Form.Item>
                      <Button type="primary" htmlType="submit">
                        Reply
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading && <WebLoader />}
    </div>
  );
};

export default QuickProposalDetails;
