import React, { useState, useEffect } from "react";
import fetchData from "../../services/requester-website";
import { imagePath } from "../../helpers/global";

const HomeBanner = () => {
  const [data, setData] = useState("");

  const getData = () => {
    let reqURL = "promotions";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data && data.length > 0 && setData(data[0] || "");
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="home-banner">
      <div className="home-banner-bg">
        <img src={imagePath() + data?.imageURL} alt="" />
        <div className="home-banner-content">
          <div className="container">
            <h2>{data?.title}</h2>
            <h5>{data?.subTitle}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
