import React, { useState, useEffect } from "react";
import fetchData from "../../services/requester-website";
import { imagePath } from "../../helpers/global";

const HomeWhyus = () => {
  const [data, setData] = useState([]);

  const getData = () => {
    let reqURL = "whyus";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data && setData(data || []);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    data &&
    data.length > 0 && (
      <div className="home-whyus">
        <h2 className="home-title">Why Choose Us</h2>
        <p className="home-sub-title">
          We Are The Best For Providing The Travel Arrangement.
        </p>
        <div className="row">
          {data?.map(
            (x, key) =>
              key < 4 && (
                <div key={key} className="col-lg-3">
                  <div className="home-whyus-wrap">
                    <img src={imagePath() + x?.imageURL} alt={x?.title} />
                    <h3>{x?.title}</h3>
                    <p>{x?.subTitle}</p>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    )
  );
};

export default HomeWhyus;
