import React, { useState, useEffect } from "react";
import fetchData from "../../services/requester-website";
import { Currency } from "../../helpers/formatter";
import { Link } from "react-router-dom";
import { imagePath } from "../../helpers/global";

const HomePackages = () => {
  const [data, setData] = useState([]);

  const getData = () => {
    let reqURL = "deals/packages";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        data && setData(data || []);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    data &&
    data.length > 0 && (
      <div className="home-deals">
        <h2 className="home-title">Flash Getaways</h2>
        <p className="home-sub-title">
          Seize Today's Exclusive Packages Before They Disappear Into the Ether!
        </p>
        <div className="row">
          {data?.map(
            (x, key) =>
              key < 4 && (
                <div key={key} className="col-lg-3">
                  <div className="home-deals-wrap">
                    <img src={imagePath() + x?.imageURL} alt={x?.title} />
                    <h3>{x?.title}</h3>
                    <h4>{Currency(x?.price)}</h4>
                    <Link to={"deals/" + x?.dealId} />
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    )
  );
};

export default HomePackages;
