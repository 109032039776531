import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Tabs, Row, Col } from "antd";
import fetchData from "../../services/requester";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadImg from "../upload-img";

const DealAdd = (props) => {
  const [data, setData] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const getData = () => {
    const { dealId } = props;
    let reqURL = "deal/" + dealId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setDefaultData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      title: data?.title,
      type: data?.type,
      location: data?.location,
      imageURL: data?.imageURL,
      price: data?.price,
      shortDescription: data?.shortDescription,
      summaryDescription: data?.summaryDescription,
      overview: data?.overview,
      itinerary: data?.itinerary,
      inclusions: data?.inclusions,
      exclusions: data?.exclusions,
      terms: data?.terms,
    });
  };

  const onFinish = (values) => {
    let reqURL = "deals/" + (props.isEditMode ? "update" : "add");
    let reqOBJ = values;

    props?.isEditMode && (reqOBJ.dealId = props?.dealId);

    fetchData(reqURL, reqOBJ, (data) => {
      data && props.handleAdd();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleUpload = (imageURL) => {
    form.setFieldsValue({ imageURL });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const col1 = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const col2 = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const col3 = {
    wrapperCol: {
      span: 24,
    },
  };

  useEffect(() => {
    props?.isEditMode && getData();
  }, []);

  return (
    <React.Fragment>
      <Form
        name="deal-add"
        className="deal-add"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ type: "hotdeals" }}
        {...layout}
      >
        <Row>
          <Col xs={24} xl={12}>
            <Form.Item label="Type" name="type" {...col1}>
              <Select placeholder="Select Deal Type">
                <Select.Option value="hotdeals">Popular Deal</Select.Option>
                <Select.Option value="packages">Package</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Title" name="title">
          <Input placeholder="Enter Title" />
        </Form.Item>

        <Row>
          <Col xs={24} xl={12}>
            <Form.Item label="Category" name="location" {...col1}>
              <Input placeholder="Enter Category" />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item label="Price" name="price" {...col2}>
              <Input placeholder="Enter Price" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={24}>
            <Form.Item label="Image" name="imageURL">
              <UploadImg
                path="uploadfile"
                type="file-input"
                handleUpload={handleUpload}
                image={props?.imageURL}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={19} offset="4">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Description" key="1">
                <Form.Item
                  name="shortDescription"
                  getValueFromEvent={(event, editor) => {
                    const data = editor.getData();
                    return data;
                  }}
                  {...col3}
                >
                  <CKEditor
                    data={data?.shortDescription || ""}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "280px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Overview" key="2">
                <Form.Item
                  name="overview"
                  getValueFromEvent={(event, editor) => {
                    const data = editor.getData();
                    return data;
                  }}
                  {...col3}
                >
                  <CKEditor
                    data={data?.overview || ""}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "280px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Itinerary" key="3">
                <Form.Item
                  name="itinerary"
                  getValueFromEvent={(event, editor) => {
                    const data = editor.getData();
                    return data;
                  }}
                  {...col3}
                >
                  <CKEditor
                    data={data?.itinerary || ""}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "280px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Inclusions" key="4">
                <Form.Item
                  name="inclusions"
                  getValueFromEvent={(event, editor) => {
                    const data = editor.getData();
                    return data;
                  }}
                  {...col3}
                >
                  <CKEditor
                    data={data?.inclusions || ""}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "280px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Exclusions" key="5">
                <Form.Item
                  name="exclusions"
                  getValueFromEvent={(event, editor) => {
                    const data = editor.getData();
                    return data;
                  }}
                  {...col3}
                >
                  <CKEditor
                    data={data?.exclusions || ""}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "280px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Terms" key="6">
                <Form.Item
                  name="terms"
                  getValueFromEvent={(event, editor) => {
                    const data = editor.getData();
                    return data;
                  }}
                  {...col3}
                >
                  <CKEditor
                    data={data?.terms || ""}
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "280px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            {(!props?.isEditMode ? "Add " : "Update") + "Deal"}
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default DealAdd;
