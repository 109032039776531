import React from "react";
import HomeBanner from "../../components/website/home-banner";
import HomeDeals from "../../components/website/home-deals";
import HomeTestimonials from "../../components/website/home-testimonials";
import HomePackages from "../../components/website/home-packages";
import HomeDestinations from "../../components/website/home-destinations";
import HomeWhyus from "../../components/website/home-whyus";

const WebHome = () => {
  return (
    <div className="web-home" style={{ minHeight: "100vh" }}>
      <HomeBanner />
      <div className="container">
        <HomeDeals />
        <HomeDestinations />
        <HomeWhyus />
        <HomePackages />
        <HomeTestimonials />
      </div>
    </div>
  );
};

export default WebHome;
