import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import AppHeader from "./components/app-header";
import AppFooter from "./components/app-footer";
import AppSidebar from "./components/app-sidebar";
import Leads from "./screens/leads";
import Customers from "./screens/customers";
import ManagePages from "./screens/website/manage-pages";
import ManageWebsite from "./screens/website/manage-website";

function AppAdmin() {
  const [portal, setPortal] = useState("");
  const { Content } = Layout;

  return (
    <Layout>
      <AppHeader setPortal={setPortal} />
      <Layout>
        <AppSidebar />
        <Content>
          <Routes>
            <Route
              key={1}
              path="/admin/leads"
              element={<Leads {...portal} />}
            />
            <Route key={2} path="/admin/customers" element={<Customers />} />
            <Route
              key={3}
              path="/admin/manage-website"
              element={<ManageWebsite {...portal} />}
            />
            <Route key={4} path="/admin/pages" element={<ManagePages />} />
          </Routes>
        </Content>
      </Layout>
      <AppFooter />
    </Layout>
  );
}

export default AppAdmin;
