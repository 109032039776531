import React, { useEffect, useState } from "react";
import fetchData from "../../services/requester-website";
import { Link } from "react-router-dom";
import { Currency } from "../../helpers/formatter";
import { imagePath } from "../../helpers/global";

const Deals = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    let reqURL = "deals";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="web-deals-list">
      {/* <div className="web-page-title">
        <div className="container">
          <h1>Exclusive Travel Deals</h1>
        </div>
      </div> */}
      <div className="mt-5">
        <div className="container">
          {!loading && (
            <div className="row">
              {data?.map((x, key) => (
                <div key={key} className="col-lg-3">
                  <div className="home-deals-wrap">
                    <img src={imagePath() + x?.imageURL} alt={x?.title} />
                    <h3>{x?.title}</h3>
                    <h4>{Currency(x?.price)}</h4>
                    <Link to={"" + x?.dealId} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Deals;
