import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Modal,
  message,
  Table,
  Space,
  Form,
  Input,
  Row,
  Col,
  Switch,
} from "antd";

import {
  FileOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import fetchData from "../../services/requester";

const ManageSettings = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();

  const getData = () => {
    let reqURL = "portal-settings";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data[0] || []);
        setDefaultData(data[0] || "");
        setLoading(false);
      },
      "GET"
    );
  };

  const onFinish = (values) => {
    let reqURL = "portal-settings/update";
    let reqOBJ = values;

    reqOBJ.portalSettingsId = data?.portalSettingsId;

    fetchData(reqURL, reqOBJ, (data) => {
      console.log(data);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      deals: data?.deals,
      packages: data?.packages,
      destinations: data?.destinations,
      testimonials: data?.testimonials,
      whyus: data?.whyus,
      promotions: data?.promotions,
      dealsTitle: data?.dealsTitle || "Hot Picks",
      dealsSubTitle:
        data?.dealsSubTitle ||
        "Unbeatable Deals Guaranteed to Brighten Your Day, Elevate Your Mood, and Lighten the Load on Your Wallet!",
      packagesTitle: data?.packagesTitle || "Flash Getaways",
      packagesSubTitle:
        data?.packagesSubTitle ||
        "Seize Today's Exclusive Packages Before They Disappear Into the Ether!",
      destinationsTitle:
        data?.destinationsTitle || "Explore Our Top Destinations",
      destinationsSubTitle:
        data?.destinationsSubTitle ||
        "Journey Through Iconic Landmarks and Exotic Hideaways in Our Top Picks!",
      whyusTitle: data?.whyusTitle || "Why Choose Us",
      whyusSubTitle:
        data?.whyusSubTitle ||
        "We Are The Best For Providing The Travel Arrangement.",
      testimonialsTitle: data?.testimonialsTitle || "Echoes of Happy Customers",
      testimonialsSubTitle:
        data?.testimonialsSubTitle ||
        "Unveiling the Heartwarming Tales That Illuminate the Path to Our Success!",
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <React.Fragment>
            <FileOutlined style={{ marginRight: "4px" }} /> Website Settings
          </React.Fragment>
        }
        ghost={false}
        style={{ border: "0px none" }}
      />

      <div className="web-settings">
        <Form
          name="web-settings"
          form={form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{}}
        >
          <h4>Home Screen Modules</h4>
          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="deals">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Popular Deals</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="dealsTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="dealsSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="packages">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Popular Packages</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="packagesTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="packagesSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="destinations">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Destinations</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="destinationsTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="destinationsSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="whyus">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Why us</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="whyusTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="whyusSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="testimonials">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Testimonials</label>
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Title" name="testimonialsTitle">
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sub Title" name="testimonialsSubTitle">
                <Input placeholder="Sub Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={3}>
              <div style={{ display: "flex" }}>
                <Form.Item name="promotions">
                  <Switch size="small" />
                </Form.Item>
                <label className="web-settings-label">Banner Promotions</label>
              </div>
            </Col>
            <Col span={6}></Col>
            <Col span={12}></Col>
          </Row>

          <Form.Item style={{ marginTop: "24px" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ManageSettings;
