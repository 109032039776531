import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card, Statistic } from "antd";
const Summary = (props) => {
  const [summary, setSummary] = useState();
  const { Title } = Typography;

  const getSummary = () => {
    const { data } = props || [];
    let totalInquiries = 0;
    let totalItineraries = 0;
    let totalQuotation = 0;
    let totalPackages = 0;
    let totalBookingInvoices = 0;
    let totalManualInvoices = 0;
    let totalCustomer = 0;

    data.map(
      (x) => (
        (totalInquiries = totalInquiries + x.totalInquiries),
        (totalItineraries = totalItineraries + x.totalItineraries),
        (totalQuotation = totalQuotation + x.totalQuotation),
        (totalPackages = totalPackages + x.noOfPackages),
        (totalBookingInvoices = totalBookingInvoices + x.totalBookingInvoices),
        (totalManualInvoices = totalManualInvoices + x.totalManualInvoices),
        (totalCustomer = totalCustomer + x.totalCustomer)
      )
    );

    let summary = {
      totalRegistrations: data?.length,
      totalInquiries: totalInquiries,
      totalItineraries: totalItineraries,
      totalQuotation: totalQuotation,
      totalPackages: totalPackages,
      totalBookingInvoices: totalBookingInvoices,
      totalManualInvoices: totalManualInvoices,
      totalCustomer: totalCustomer,
    };

    setSummary(summary);
  };

  useEffect(() => {
    getSummary();
  }, []);

  return (
    <React.Fragment>
      <Row
        gutter={16}
        style={{ marginBottom: "12px" }}
        className="leads-summary"
      >
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title={"All"}
              value={summary?.totalRegistrations || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title={"New"}
              value={summary?.totalRegistrations || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title={"Unassigned"}
              value={summary?.totalInquiries || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title="Followup"
              value={summary?.totalItineraries || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title="Potential"
              value={summary?.totalQuotation || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title="Positive"
              value={summary?.totalPackages || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title="Converted"
              value={summary?.totalBookingInvoices || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card
            size="small"
            style={{ textAlign: "center", maxHeight: "90px" }}
            loading={props?.loading}
          >
            <Statistic
              title="Closed"
              value={summary?.totalManualInvoices || 0}
              valueStyle={{ textAlign: "center" }}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Summary;
