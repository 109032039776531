import config from "../config.json";

const appPath = config?.mode === "dev" ? config.devPath : config.livePath;

const fetchFile = (reqURL, reqOBJ, callback, reqMethod) => {
  return fetch(appPath + reqURL, {
    method: reqMethod || "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
    body: reqOBJ ? reqOBJ : null,
  })
    .then((res) => res.json())
    .then((data) => {
      callback(data);
    });
};

export default fetchFile;
