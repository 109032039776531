import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import fetchData from "../../services/requester-website";
import WebsiteLogo from "../../assets/images/website/website-logo.png";

const WebsiteHeader = (props) => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const path = "/";

  const getData = () => {
    let reqURL = "pages";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        props.setMenu(data || []);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <header className="web-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex align-items-center">
            <Link to={path} className="web-logo">
              <img
                src={WebsiteLogo}
                alt={props?.company}
                className="web-default-logo"
              />
              {props?.company}
            </Link>
          </div>
          <div className="col-lg-8 d-flex justify-content-end align-items-center">
            <div className="navbar">
              <ul className="list-unstyled p-0 m-0 d-flex align-items-center">
                <li>
                  <Link to={path}>Home</Link>
                </li>
                <li>
                  <Link to={path + "deals"}>Deals</Link>
                </li>
                {data?.map((x, key) => (
                  <li key={key}>
                    <Link to={path + x?.pageName}>{x?.pageTitle}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default WebsiteHeader;
