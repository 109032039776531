import React, { useState } from "react";
import { Layout, Menu } from "antd";
import {
  MailOutlined,
  AuditOutlined,
  ReconciliationOutlined,
  FileProtectOutlined,
  SettingOutlined,
  UserOutlined,
  BarChartOutlined,
  DashboardOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const AppSidebar = () => {
  const [collapsed, setCollapsed] = useState(true);

  const { Sider } = Layout;

  const navigate = useNavigate();

  const items = [
    {
      key: "manage-website",
      icon: <DesktopOutlined style={{ fontSize: "18px" }} />,
      label: "Website",
    },
    {
      key: "leads",
      icon: <MailOutlined style={{ fontSize: "18px" }} />,
      label: "Leads",
    },
    {
      key: "customers",
      icon: <UserOutlined style={{ fontSize: "18px" }} />,
      label: "Customers",
    },

    // {
    //   key: "proposals",
    //   icon: <FileProtectOutlined style={{ fontSize: "18px" }} />,
    //   label: "Proposals",
    // },
    // {
    //   key: "invoices",
    //   icon: <AuditOutlined style={{ fontSize: "18px" }} />,
    //   label: "Invoices",
    // },
    // {
    //   key: "accounts",
    //   icon: <ReconciliationOutlined style={{ fontSize: "18px" }} />,
    //   label: "Accounts",
    // },
    // {
    //   key: "reports",
    //   icon: <BarChartOutlined style={{ fontSize: "18px" }} />,
    //   label: "Reports",
    // },
    // {
    //   key: "settings",
    //   icon: <SettingOutlined style={{ fontSize: "18px" }} />,
    //   label: "Settings",
    // },
  ];

  const onClick = ({ key }) => {
    navigate("/admin/" + key);
  };

  return (
    <Sider
      width={216}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme={"dark"}
      style={{ zIndex: "100" }}
    >
      <Menu
        onClick={onClick}
        mode="inline"
        style={{
          height: "100%",
          paddingTop: "16px",
          minHeight: "100vh",
        }}
        items={items}
        theme={"dark"}
      />
    </Sider>
  );
};

export default AppSidebar;
