import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  WhatsAppOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import fetchData from "../services/requester";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadImg from "../components/upload-img";

const AddQuickProposal = (props) => {
  const [data, setData] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const getData = () => {
    const { leadId } = props;
    let reqURL = "quick-proposals/" + leadId;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data && data?.length > 0) {
          setIsEditMode(true);
          setData(data[0] || "");
          setDefaultData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      title: data?.title,
      details: data?.details,
      offerPrice: data?.offerPrice,
    });
  };

  const onFinish = (values) => {
    const { leadId, customerId } = props;
    const { quickProposalId } = data;
    values.leadId = leadId;
    values.customerId = customerId;

    let reqURL = "quick-proposals/" + (isEditMode ? "update" : "add");
    let reqOBJ = values;

    isEditMode && (reqOBJ.quickProposalId = quickProposalId);

    fetchData(reqURL, reqOBJ, (data) => {
      data && props.handleAdd();
    });
  };

  const handleUpload = (imageURL) => {
    form.setFieldsValue({ imageURL });
  };

  const handleShare = () => {
    const { phone, portal } = props;
    const { url } = portal;
    const { leadId } = data;

    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    let path = "/website/" + url + "/quick-proposal-details/" + leadId;
    let message = "Your Proposal - ";
    let whatsApp = "";

    if (isMobile) {
      whatsApp = "whatsapp://send?phone=" + phone + "&text=";
    } else {
      whatsApp = "https://web.whatsapp.com/send?phone=" + phone + "&text=";
    }

    window.open(
      whatsApp + encodeURIComponent(message + window.origin + path),
      "_blank"
    );
  };

  const handlePreview = () => {
    const { leadId } = data;
    const { portal } = props;
    const { url } = portal;
    let path = "/website/" + url + "/quick-proposal-details/" + leadId;
    window.open(window.origin + path, "_blank");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <Row gutter={8} style={{ marginBottom: "16px" }}>
        <Col span={4} style={{ textAlign: "right" }}>
          Lead Title :
        </Col>
        <Col span={20}>{props?.title}</Col>
      </Row>
      <Row gutter={8} style={{ marginBottom: "24px" }}>
        <Col span={4} style={{ textAlign: "right" }}>
          Customer :
        </Col>
        <Col span={20}>
          {props?.name + " - " + props?.email + " - " + props?.phone}
        </Col>
      </Row>
      <Form
        name="quickproposal-add"
        form={form}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Title" name="title">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Details"
              name="details"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
            >
              <CKEditor
                data={data?.details || ""}
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "190px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Image" name="imageURL">
              <UploadImg
                path="uploadfile"
                type="file-input"
                handleUpload={handleUpload}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Offer Price" name="offerPrice">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 18,
          }}
          className="btn-row"
        >
          <Button
            type="primary"
            htmlType="submit"
            icon={!isEditMode ? <PlusOutlined /> : <EditOutlined />}
          >
            {!isEditMode ? "Add" : "Update"}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            icon={<WhatsAppOutlined />}
            onClick={handleShare}
          >
            Share
          </Button>

          {isEditMode && (
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={handlePreview}
            >
              Preview
            </Button>
          )}
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default AddQuickProposal;
