import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import fetchData from "../../services/requester-website";
import parse from "html-react-parser";
import WhatsAppIcon from "../../assets/images/website/whatsapp-icon.svg";
import WebLoader from "../../components/website/web-loader";
import { imagePath } from "../../helpers/global";

const DealDetails = (props) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const { phone } = props;

  const getData = () => {
    const { id } = params;

    let reqURL = "destinations/" + id;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        if (data) {
          setData(data[0] || "");
          setLoading(false);
        }
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="web-details destination-details">
      {!loading && (
        <div className="container">
          <div className="pt-4">
            <div className="row">
              <div className="col-lg-8">
                {data?.imageURL && (
                  <div className="web-details-image">
                    <h2>{data?.title}</h2>
                    <img src={imagePath() + data?.imageURL} alt={data?.title} />
                  </div>
                )}

                <div className="web-details-sec">
                  {data?.shortDescription && (
                    <div className="text-secondary mb-4">
                      {parse("" + data?.shortDescription + "")}
                    </div>
                  )}

                  {data?.overview && (
                    <div>{parse("" + data?.overview + "")}</div>
                  )}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="web-details-info">
                  <div className="web-details-title">
                    <h2>{data?.title}</h2>
                  </div>
                  <div className="web-details-info-wrap">
                    {data?.shortDescription && (
                      <div className="text-secondary mb-4">
                        {parse("" + data?.shortDescription + "")}
                      </div>
                    )}
                    <h5>
                      Discover deals for {data?.title}!
                      <br />
                      Connect on WhatsApp for exclusive deals
                    </h5>

                    <div className="row web-details-btns g-0">
                      <Link
                        className="btn btn-whatsapp"
                        target="_blank"
                        to={
                          "https://web.whatsapp.com/send?phone=" +
                          phone +
                          "&text=" +
                          encodeURIComponent(
                            "I am interested: " + window.location
                          )
                        }
                      >
                        <img src={WhatsAppIcon} alt="" />
                        WhatsApp
                      </Link>
                      <Link
                        className="btn btn-whatsapp btn-whatsapp-mobile d-none"
                        target="_blank"
                        to={
                          "https://wa.me/" +
                          phone +
                          "?text=" +
                          encodeURIComponent(
                            "I am interested: " + window.location
                          )
                        }
                      >
                        <img src={WhatsAppIcon} alt="" />
                        WhatsApp
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading && <WebLoader />}
    </div>
  );
};

export default DealDetails;
